<template>
	<Layout>
		<!-- <PageHeader :title="title" :items="items" /> -->
		<div class="row">
			<div class="col-12">
				<ul class="nav nav-tabs pt-3" style="border: 0;">
					<li class="active"><a href="#" class="btn btn-sm btn-head text-white">Purchase Order Detail</a></li>
				</ul>
				<div class="card new-card">
					<div class="card-body">
						<div class="row mb-2">
							<div class="col-md-3">
								<span style="padding-top: 20px;"> <b>PPN :</b> </span> <span> {{ mapPO.total_ppn }}</span>
							</div>
							<div class="col-md-3">
								<span style="padding-top: 20px;"> <b>PPH :</b> </span> <span> {{ mapPO.total_pph }}</span>
							</div>
							<div class="col-md-3">
								<span style="padding-top: 20px;"> <b>GRAND TOTAL :</b> </span> <span> {{ mapPO.grand_total }} </span>
							</div>
							<div class="col-md-3">
								<input type="text" v-model="searchQuery" class="form-control form-control-sm" placeholder="Search"
									@input="searchPO" />
							</div>
						</div>

						<div class="card border" v-for="(dtItem, idx) in mapPO.data" :key="idx">
							<div class="card-header " style="background: #d8f8eb; padding: 0px;">
								<div class="row">
									<div class="col-6" style="display: flex;align-items: center;">
										<div class="ml-2">
											<span style="color: #000;font-size: 16px;">Jobfile : </span>
											<a :href="urlCi + `/admin/jobfile/data?jobfile=${dtItem.item_details.jobfile_id}`"
												style="color: #3A1AFF;font-size: 16px;color: #3A1AFF;font-weight: 600;">{{
													dtItem.item_details.code
												}}</a>
										</div>
									</div>
									<div class="col-6" style="display: flex;align-items: center;justify-content: end; m">
										<!-- Using value -->
										<a v-b-toggle="`collapse` + idx" class="m-1 mr-3"
											style="background: none;border: none;transform: rotate(360deg);font-size: 20px;color: #51C1AD;">
											<i class="fas fa-angle-down"></i>
										</a>
									</div>
								</div>
							</div>

							<b-collapse :id="`collapse` + idx">
								<div class="card-body table-responsive" style="background: #93EDC71A;">
									<table id="tcost1" class="table table-bordered table-striped small mt-2" width="100%">
										<thead>
											<tr style="background:#51C1AD;color:#fff;">
												<th width="5%">No</th>
												<th>Item</th>
												<th>Qty</th>
												<th style="text-align: right">Amount</th>
												<th>Sub Total</th>
												<th style="text-align: center">Remark</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(dtItem, idx) in dtItem.item" :key="idx">
												<td>{{ idx + 1 }}</td>
												<td>{{ dtItem.item.item_name }}</td>
												<td>{{ dtItem.qty }}</td>
												<td>{{ dtItem.currency_item + ' ' + dtItem.amount }}</td>
												<td> {{
													dtItem.currency + ' ' + (dtItem.amount * mapPO.amount_kurs) *
														dtItem.qty
												}}
												</td>
												<td>
													{{ dtItem.remark }}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</b-collapse>

						</div>

						<b-button class="btn-cancel" @click="onAction('Back')">
							Back
						</b-button>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>
<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import { mapActions, mapState } from 'vuex'
import { authMethods } from '@state/helpers'


export default {

	page: {
		title: 'Purchase Order Detail',
		meta: [{
			name: 'description',
			content: appConfig.description,
		},],
	},
	components: {
		Layout,
		// PageHeader,
	},
	data() {
		return {
			title: 'Purchase Order',
			items: [{
				text: 'Master',
				href: '/',
			},
			{
				text: 'Purchase Order',
				href: '/purchase-order',
			},
			{
				text: 'List',
				active: true,
			},
			],
			urlCi: process.env.VUE_APP_CI_URL,
			mapPO: '',
			searchQuery: ''

		}
	},
	computed: {
		...mapState('purchaseOrder', ['listPOdetail'])
	},
	mounted() {
		this.mappingData()
		// this.getlistPO(window.location.href.split('/').splice(0, 6)[5])
	},
	methods: {
		...authMethods,
		errorAlertValidation: function (message = 'Terjadi Kesalahan') {
			this.$swal({
				title: message,
				type: 'error',
				timer: '2000',
				toast: true,
				position: 'bottom-end',
			})
		},
		...mapActions('purchaseOrder', ['getlistPoDetails']),
		mappingData() {
			let dataPo = Promise.resolve(this.getlistPoDetails({ id: window.location.href.split('/').splice(0, 6)[5] }));
			dataPo.then(value => {
				this.mapPO = value[0]
			})
		},
		searchPO() {
			let props = {
				id: window.location.href.split('/').splice(0, 6)[5],
				search: this.searchQuery
			}

			let dataPo = Promise.resolve(this.getlistPoDetails(props));
			this.mapPO = ''
			dataPo.then(value => {
				this.mapPO = value[0]
			})
		},

		onAction(action, rowData, rowIndex) {
			if (action === 'Back') {
				this.$router.push({
					name: 'PurchaseOrder List'
				})
			}
		},
	},
}
</script>
<style>
.header-jobfile {
	min-height: 60px;
	background: #FFF;
	display: grid;
	align-items: center;
}

a.not-collapsed i {
	transform: rotate(180deg);
}

button.not-collapsed i {
	transform: rotate(180deg);
}

.vue-ads-leading-loose {
	display: none;
}
</style>
